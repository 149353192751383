/**
 * Labstep
 *
 * @module components/ProtocolCondition/Card/EmptyState
 * @desc Empty state for ProtocolCondition
 */

import EmptyState from 'labstep-web/core/Card/EmptyState';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { getHumanReadableEntityName } from 'labstep-web/services/i18n.service';
import React from 'react';

const label = getHumanReadableEntityName(
  ProtocolCondition.entityName,
  true,
  true,
);

export const ProtocolConditionEmptyState: React.FC = () => (
  <EmptyState
    src="/img/empty-state/inventory.svg"
    title={`No ${label}`}
    explanation={
      <>
        <span>TODO</span>
      </>
    }
  />
);
