/**
 * Labstep
 *
 * @module screens/ProtocolCondition/Index/Breadcrumb
 * @desc ProtocolCondition Index Breadcrumb
 */

import Link from 'labstep-web/core/Link';
import React from 'react';
import { ProtocolConditionShowBreadcrumbProps } from './types';

export const ProtocolConditionShowBreadcrumb: React.FC<
  ProtocolConditionShowBreadcrumbProps
> = ({ protocol, groupId }) => (
  <Link to="group_protocol_conditions" params={{ id: groupId }}>
    {protocol.name}
  </Link>
);

export default ProtocolConditionShowBreadcrumb;
