/**
 * Labstep
 *
 * @desc Protocol Condition DataGrid Reporting
 */

import { ColDef } from 'ag-grid-community';
import EntityDataGrid from 'labstep-web/components/Entity/DataGrid';
import { AGGridEntityReportingService } from 'labstep-web/services/agGrid/ag-grid-reporting.service';
import React from 'react';
import { IProtocolConditionDataGridReportingProps } from './types';

const ProtocolConditionDataGridReporting: React.FC<
  IProtocolConditionDataGridReportingProps
> = ({ protocol, protocolConditions, loadMore, status }) => {
  const columnDefs: ColDef[] =
    AGGridEntityReportingService.getColDef(protocol);

  console.log('protocol', protocol);
  console.log('protocolConditions', protocolConditions);
  console.log('columnDefs', columnDefs);

  return (
    <EntityDataGrid
      columnDefs={columnDefs}
      entities={protocolConditions}
      loadMore={loadMore}
      status={status}
    />
  );
};

export default ProtocolConditionDataGridReporting;
