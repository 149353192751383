/**
 * Labstep
 *
 * @module services/ag-grid-reporting
 * @desc AGGrid Service Reporting
 */

import { ColDef } from 'ag-grid-community';
import { CellRendererParams } from 'labstep-web/core/DataGrid/types';
import { Metadata } from 'labstep-web/models/metadata';
import { ProtocolCondition } from 'labstep-web/models/protocol-condition.model';
import { ProtocolValue } from 'labstep-web/models/protocol-value.model';
import { Protocol } from 'labstep-web/models/protocol.model';
import { get } from 'lodash';
import { AGGridService } from './ag-grid.service';

export class AGGridEntityReportingService extends AGGridService {
  public static metadataCellRenderer = (
    metadatas: Metadata[],
    metadata: Metadata,
  ) => {
    const foundMetadata = metadatas.find(
      (dataMetadata: Metadata) =>
        dataMetadata.label === metadata.label,
    );
    if (!foundMetadata) {
      return '';
    }
    return foundMetadata.display_value || '';
  };

  public static protocolValueCellRenderer = (
    protocolValues: ProtocolValue[],
    protocolValue: ProtocolValue,
    path: string,
  ) => {
    const foundProtocolValue =
      AGGridEntityReportingService.getProtocolValue(
        protocolValues,
        protocolValue,
      );
    if (!foundProtocolValue) {
      return '';
    }
    return get(foundProtocolValue, path) || '';
  };

  public static getProtocolValue = (
    protocolValues: ProtocolValue[],
    protocolValue: ProtocolValue,
  ) =>
    protocolValues.find(
      (dataProtocolValue: ProtocolValue) =>
        dataProtocolValue.name === protocolValue.name,
    );

  public static getColDef = (protocol: Protocol): ColDef[] => {
    const columnDefs: ColDef[] = [
      {
        colId: `experiment_name`,
        headerName: 'Experiment',
        field: 'experiment.experiment_workflow.name',
      },
    ];

    let metadataKey = 0;
    protocol.metadatas_without_variable_template.forEach(
      (metadata) => {
        columnDefs.push({
          colId: `metadata_${metadataKey}`,
          headerName: metadata.label || '',
          cellRenderer: (
            params: CellRendererParams<ProtocolCondition>,
          ) =>
            AGGridEntityReportingService.metadataCellRenderer(
              params.node.data.metadatas,
              metadata,
            ),
        });
        metadataKey += 1;
      },
    );

    const protocolValueKey = 0;
    protocol.protocol_values_without_variable_template.forEach(
      (protocolValue) => {
        columnDefs.push({
          colId: `protocol_value_${protocolValueKey}`,
          headerName: `Amount${
            protocolValue.name ? ` ${protocolValue.name}` : ''
          }`,
          cellRenderer: (
            params: CellRendererParams<ProtocolCondition>,
          ) =>
            AGGridEntityReportingService.protocolValueCellRenderer(
              params.node.data.protocol_values,
              protocolValue,
              'amount',
            ),
        });
        columnDefs.push({
          colId: `protocol_value_resource_item_${protocolValueKey}`,
          headerName: protocolValue.name || '',
          cellRenderer: (
            params: CellRendererParams<ProtocolCondition>,
          ) =>
            AGGridEntityReportingService.protocolValueCellRenderer(
              params.node.data.protocol_values,
              protocolValue,
              'resource_item.name',
            ),
        });

        let resourceItemMetadataKey = 0;
        protocolValue.resource_template?.resource_item_template?.metadata_thread.metadatas.forEach(
          (metadata) => {
            columnDefs.push({
              colId: `protocol_value_resource_template_resource_item_template_metadata_${resourceItemMetadataKey}`,
              headerName: `${metadata.label} - ${protocolValue.name}`,
              cellRenderer: (
                params: CellRendererParams<ProtocolCondition>,
              ) => {
                const foundProtocolValue =
                  AGGridEntityReportingService.getProtocolValue(
                    params.node.data.protocol_values,
                    protocolValue,
                  );
                if (!foundProtocolValue) {
                  return '';
                }
                return AGGridEntityReportingService.metadataCellRenderer(
                  foundProtocolValue.resource_item!.metadata_thread
                    .metadatas,
                  metadata,
                );
              },
            });
            resourceItemMetadataKey += 1;
          },
        );

        let resourceMetadataKey = 0;
        protocolValue.resource_template?.metadata_thread.metadatas.forEach(
          (metadata) => {
            columnDefs.push({
              colId: `protocol_value_resource_template_metadata_${resourceMetadataKey}`,
              headerName: `${metadata.label} - ${protocolValue.name}`,
              cellRenderer: (
                params: CellRendererParams<ProtocolCondition>,
              ) => {
                const foundProtocolValue =
                  AGGridEntityReportingService.getProtocolValue(
                    params.node.data.protocol_values,
                    protocolValue,
                  );
                if (
                  !foundProtocolValue ||
                  !foundProtocolValue.resource_item
                ) {
                  return '';
                }
                return AGGridEntityReportingService.metadataCellRenderer(
                  foundProtocolValue.resource_item!.resource
                    .metadata_thread.metadatas,
                  metadata,
                );
              },
            });
            resourceMetadataKey += 1;
          },
        );

        resourceItemMetadataKey += 1;
      },
    );

    return columnDefs;
  };
}
