/**
 * Labstep
 *
 * @module screens/ProtocolCondition/Index
 * @desc ProtocolCondition Index
 */

import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import ProtocolCollectionSearchSelect from 'labstep-web/components/ProtocolCollection/SearchSelect';
import EmptyState from 'labstep-web/core/Card/EmptyState';
import DocumentHead from 'labstep-web/core/DocumentHead';
import { ProtocolCollection } from 'labstep-web/models/protocol-collection.model';
import { navigation } from 'labstep-web/services/navigation';
import React from 'react';
import { withRouter } from 'react-router';
import styles from './styles.module.scss';
import { IProtocolConditionIndexProps } from './types';

export const ProtocolConditionIndex: React.FC<
  IProtocolConditionIndexProps
> = ({ group, history }) => (
  <>
    <DocumentHead title="Reporting" />
    <LayoutMainContentCenter>
      <div className={styles.container}>
        <EmptyState
          src="/img/empty-state/inventory.svg"
          title="Reporting"
          explanation={
            <>
              <span>
                Select a protocol to begin exploring data collected
                across all experiments
              </span>
            </>
          }
          action={
            <ProtocolCollectionSearchSelect
              onChange={(option: ProtocolCollection) => {
                history.push(
                  navigation.get('protocol_condition_show', {
                    id: group.id,
                    protocolId: option.id,
                  }),
                );
              }}
            />
          }
        />
      </div>
    </LayoutMainContentCenter>
  </>
);

export default withRouter(ProtocolConditionIndex);
