/**
 * Labstep
 *
 * @module components/Academic/SetupPayment
 * @desc Show modal with wizard to upgrade academic account
 */

import OrganizationPlanManagerPrice from 'labstep-web/components/OrganizationPlan/Manager/Price';
import OrganizationPlanManagerSubmit from 'labstep-web/components/OrganizationPlan/Manager/Submit';
import { defaultCurrency } from 'labstep-web/constants/organization-plan-price';
import ModalWizard from 'labstep-web/core/Modal/Wizard';
import { OrganizationPlanPrice } from 'labstep-web/models/organization-plan-price.model';
import { CurrencyISO } from 'labstep-web/services/currency.service';
import React, { useState } from 'react';
import { ActionComponent } from 'labstep-web/core/Action/Component';
import { AcademicSetupPaymentProps } from './types';

const ROUTE_ID = 'academic-upgrade';

export const AcademicSetupPayment: React.FC<
  AcademicSetupPaymentProps
> = ({ actionComponentProps, ...rest }) => {
  const [activePlanPrice, setActivePlanPrice] =
    useState<OrganizationPlanPrice | null>(null);
  const [priceCurrency, setPriceCurrency] =
    useState<CurrencyISO>(defaultCurrency);

  return (
    <ModalWizard
      mode="fullscreen"
      routeId={ROUTE_ID}
      title="Upgrade"
      trigger={({ toggleModal }) => (
        <ActionComponent
          type="button"
          {...actionComponentProps}
          onClick={() => toggleModal()}
        />
      )}
      steps={['Select a plan', 'Setup payment']}
      {...rest}
    >
      <ModalWizard.Step index={0}>
        <OrganizationPlanManagerPrice
          priceCurrency={priceCurrency}
          setPriceCurrency={setPriceCurrency}
          activePlanPrice={activePlanPrice}
          setActivePlanPrice={setActivePlanPrice}
          academic
        />
      </ModalWizard.Step>
      <ModalWizard.Step index={1}>
        {activePlanPrice && (
          <OrganizationPlanManagerSubmit
            priceId={activePlanPrice.id}
            currency={priceCurrency}
          />
        )}
      </ModalWizard.Step>
    </ModalWizard>
  );
};

export default AcademicSetupPayment;
