/**
 * Labstep
 *
 * @module screens/Group/Show/Settings
 * @desc Group settings
 */

import { GroupActionManageMembersTable } from 'labstep-web/components/Group/Action/ManageMembers/Table';
import GroupSettings from 'labstep-web/components/Group/Settings';
import GroupSettingsDevices from 'labstep-web/components/Group/Settings/Devices';
import GroupSettingsExperiment from 'labstep-web/components/Group/Settings/Experiment';
import Export from 'labstep-web/components/Group/Settings/Export';
import GroupSettingsInventory from 'labstep-web/components/Group/Settings/Inventory';
import GroupSettingsOrdering from 'labstep-web/components/Group/Settings/Ordering';
import GroupSettingsProtocols from 'labstep-web/components/Group/Settings/Protocols';
import GroupAutoSharingList from 'labstep-web/components/GroupAutoSharing/List';
import LayoutLinksVertical from 'labstep-web/components/Layout/Links/Vertical';
import LayoutLinksVerticalMobile from 'labstep-web/components/Layout/Links/Vertical/Mobile';
import { ILayoutLinksVerticalGroupsProps } from 'labstep-web/components/Layout/Links/Vertical/types';
import { IEntityCardLink } from 'labstep-web/components/Layout/Links/types';
import LayoutMainContentCenter from 'labstep-web/components/Layout/MainContent/Center';
import UserGroupNotificationManager from 'labstep-web/components/UserGroupNotification/Manager';
import { ICONS } from 'labstep-web/constants/icons';
import Card from 'labstep-web/core/Card/Card';
import Flex from 'labstep-web/core/Flex';
import Icon from 'labstep-web/core/Icon';
import Mobile from 'labstep-web/core/MediaQuery/Mobile';
import { useHasFeatureFlagEnabled } from 'labstep-web/hooks/feature-flag';
import { STRINGS } from 'labstep-web/strings';
import React from 'react';
import ShareLinkInvitationModal from 'labstep-web/components/ShareLinkInvitation/Modal/ViewPendingInvites';
import styles from './styles.module.scss';
import { IGroupShowSettingsProps } from './types';

export const GroupShowSettings: React.FC<IGroupShowSettingsProps> = ({
  group,
}) => {
  const userGroup = group.logged_user_user_group;

  const links: IEntityCardLink[] = [
    {
      route: {
        to: 'group_settings',
        params: { id: group.id },
      },
      icon: 'cogs',
      children: STRINGS.navigation.general.singular.capitalized,
      render: (): React.ReactElement => (
        <GroupSettings group={group} />
      ),
    },
    {
      route: {
        to: 'group_users',
        params: { id: group.id },
      },
      icon: ICONS.user.secondary,
      children: STRINGS.user.plural.capitalized,
      render: (): React.ReactElement => (
        <Flex grow column spacing="gap">
          {group.canInvite && (
            <ShareLinkInvitationModal entity={group} />
          )}
          <GroupActionManageMembersTable group={group} />
        </Flex>
      ),
    },
    {
      children: 'Experiments',
      icon: ICONS.experiment_workflow.primary,
      route: {
        to: 'group_settings_experiment_workflow',
        params: { id: group.id },
      },
      routes: [
        'group_settings_experiment_workflow',
        'group_settings_experiment_entity_state_workflow',
        'group_settings_experiment_workflow_templates',
        'group_settings_experiment_tags',
      ],
      render: () => <GroupSettingsExperiment group={group} />,
    },
    {
      children: 'Protocols',
      icon: ICONS.protocol_collection.primary,
      route: {
        to: 'group_settings_protocol_tags',
        params: { id: group.id },
      },
      routes: ['group_settings_protocol_tags'],
      render: () => <GroupSettingsProtocols group={group} />,
    },
  ];

  if (useHasFeatureFlagEnabled('inventory')) {
    links.push({
      children: 'Inventory',
      icon: ICONS.resource.primary,
      route: {
        to: 'group_settings_inventory_categories',
        params: { id: group.id },
      },
      routes: [
        'group_settings_inventory_categories',
        'group_settings_inventory_locations',
        'group_settings_inventory_tags',
      ],
      render: () => <GroupSettingsInventory group={group} />,
    });
  }

  if (useHasFeatureFlagEnabled('order_management')) {
    links.push({
      children: 'Order Management',
      icon: ICONS.order_request.primary,
      route: {
        to: 'group_settings_order_request_template',
        params: { id: group.id },
      },
      routes: [
        'group_settings_order_request_template',
        'group_settings_purchase_order_template',
        'group_settings_order_request_tags',
      ],
      render: () => <GroupSettingsOrdering group={group} />,
    });
  }

  if (useHasFeatureFlagEnabled('devices')) {
    links.push({
      children: 'Devices',
      icon: ICONS.device.primary,
      route: {
        to: 'group_settings_device_categories',
        params: { id: group.id },
      },
      routes: [
        'group_settings_device_categories',
        'group_settings_device_tags',
      ],
      render: () => <GroupSettingsDevices group={group} />,
    });
  }

  const groups: ILayoutLinksVerticalGroupsProps[] = [];

  if (userGroup) {
    groups.push({
      label: 'Personal',
      links: [
        {
          children: 'Notifications',
          icon: 'bell',
          route: {
            to: 'group_settings_notifications',
            params: { id: group.id },
          },
          render: () => <UserGroupNotificationManager />,
        },
      ],
    });

    if (userGroup.type === 'owner') {
      links.push({
        children: 'Auto-Sharing',
        icon: 'share alternate',
        route: {
          to: 'group_settings_auto_sharing',
          params: { id: group.id },
        },
        render: () => <GroupAutoSharingList group={group} />,
        premiumFeature: 'auto_sharing',
      });
      links.push({
        children: 'Export',
        icon: 'download',
        route: {
          to: 'group_settings_export',
          params: { id: group.id },
        },
        render: () => <Export />,
      });
    }
  }

  return (
    <Mobile>
      {({ match }) =>
        match ? (
          <LayoutLinksVerticalMobile
            label="Workspace Settings"
            groups={groups}
            links={links}
          />
        ) : (
          <LayoutMainContentCenter>
            <Card className={styles.card}>
              <div className={styles.primaryInfo}>
                <div>
                  <Icon
                    name={ICONS.navigation.settings}
                    size="large"
                    color="grey"
                  />
                  Workspace Settings
                </div>
              </div>
              <LayoutLinksVertical links={links} groups={groups} />
            </Card>
          </LayoutMainContentCenter>
        )
      }
    </Mobile>
  );
};

export default GroupShowSettings;
