import { Limits, LimitTypes } from 'labstep-web/constants/limits';
import { selectAuthenticatedUserEntity } from 'labstep-web/state/selectors/authenticated-user';
import { useSelector } from 'react-redux';

export const useHasExceededLimit = (
  count: number,
  limitType: LimitTypes,
): boolean => {
  const hasExceeded = count >= Limits[limitType];

  // This check is added to prevent the upgrade modal from showing when the user has an organization
  const hasOrganization = useSelector((state) => {
    const authenticatedUserEntity =
      selectAuthenticatedUserEntity(state);
    if (!authenticatedUserEntity) {
      return false;
    }
    return authenticatedUserEntity.organization;
  });
  return hasOrganization ? false : hasExceeded;
};
